import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import router from "@/router";
import SchedulerApiService from "@/core/services/api.service.scheduler";
import CountryService from "@/core/services/country.service";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "scheduler-cities",
  data() {
    return {
      dataSource: [],
      countries: [],
      selectedCountry: undefined,
      countriesLoading: false,
      loading: false
    };
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await SchedulerApiService.init();
    this.getCountries();
  },
  methods: {
    onGoToCalendar(value) {
      router.push({ path: `/scheduler/${this.selectedCountry.id}/${value.id}`, replace: true });
    },
    getCities() {
      this.loading = true;
      SchedulerApiService.getCities({
        countryId: this.selectedCountry?.id
      })
        .then(res => {
          this.dataSource = res.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCountries() {
      this.countriesLoading = true;
      SchedulerApiService.getCountries()
        .then(res => {
          this.countries = res.data || [];

          if (!this.selectedCountry) {
            const countryId = CountryService.getActiveCountry();
            this.selectedCountry = this.countries.find(country => country.id === countryId);
          }

          this.getCities();
        })
        .finally(() => {
          this.countriesLoading = false;
        });
    },
    onCountryChange() {
      this.getCities();
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    name() {
      return this.$i18n.t("menu.scheduler");
    },
    headers() {
      return [
        { text: "Id", align: "start", value: "id", width: "5%", sortable: false },
        { text: this.$i18n.t("common.city"), align: "start", sortable: false },
        { text: "", align: "start", width: "5%", sortable: false }
      ];
    }
  }
};
